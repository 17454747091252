<template>
  <div class="app-box bg-color">
    <div class="data-tip">数据更新时间为{{upDateTime.time}}，此页面数据仅作为参考。</div>
    <x-tab :tabList="tabList" @change="tabChange"></x-tab>
    <x-sale-index v-show="tabList[0].check" :upDateTime="upDateTime" :show="tabList[0].check"></x-sale-index>
    <x-goods-index v-show="tabList[1].check" :upDateTime="upDateTime" :show="tabList[1].check"></x-goods-index>
  </div>
</template>

<script>
import tab from "@/components/tab";
import saleIndex from "@/page/reportCenter/apparatus/sale/index";
import goodsIndex from "@/page/reportCenter/apparatus/goods/index";
import { cachePage } from "@/script/mixins/cachePage";

export default {
  name: "apparatusIndex",
  data () {
    return {
      upDateTime: {
        time: null
      },
      tabList: [{
        id: 1,
        text: "销售业绩",
        check: true
      }, {
        id: 2,
        text: "货品销售",
        check: false
      }]
    };
  },
  mixins: [cachePage],
  created () {
    document.title = "器械销售数据";
  },
  methods: {
    tabChange (item) {
      console.log(item);
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "器械销售数据";
      }
    }
  },
  components: {
    [tab.name]: tab,
    [saleIndex.name]: saleIndex,
    [goodsIndex.name]: goodsIndex
  }
};
</script>

<style scoped>
</style>
